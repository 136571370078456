import { useEffect } from 'react';

import { useLocation } from '@remix-run/react';

const PIXEL_ID = '1415671029081310';

export function MetaPixel() {
  const location = useLocation();

  useEffect(() => {
    // Load the Meta Pixel script
    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) {
        return;
      }
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) {
        f._fbq = n;
      }
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(
      window,
      document,
      'script',
      'https://connect.facebook.net/en_US/fbevents.js'
    );

    // Initialize the pixel
    window.fbq('init', PIXEL_ID);

    // Track the initial pageview
    window.fbq('track', 'PageView');
  }, []);

  useEffect(() => {
    // Track pageviews on route changes
    window.fbq('track', 'PageView');
  }, [location]);

  return (
    <noscript>
      <img
        height="1"
        width="1"
        style={{ display: 'none' }}
        src={`https://www.facebook.com/tr?id=${PIXEL_ID}&ev=PageView&noscript=1`}
        alt=""
      />
    </noscript>
  );
}
