import {
  isRouteErrorResponse,
  Link,
  Links,
  Meta,
  MetaFunction,
  Scripts
} from '@remix-run/react';
import { Button } from '@web/components/ui/button';

import '../../styles.css';

import { landingPage } from '@web/utilities/routes';

import errorImage from './controler.webp';

type ErrorPageProps = {
  error: unknown;
};

export const meta: MetaFunction = () => {
  return [
    { title: 'Page Not Found - Onezee' },
    {
      name: 'description',
      content:
        'The page you are looking for could not be found. It may have been moved or deleted. Please check the URL or return to the homepage.'
    },
    {
      name: 'keywords',
      content: '404 error, page not found, Onezee, error page, broken link'
    }
  ];
};

export function ErrorPage({ error }: ErrorPageProps) {
  const isResponseError = isRouteErrorResponse(error);

  return (
    <html lang="en" className="h-full">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>
          {isResponseError ? `${error.status} ${error.statusText}` : 'Error'}
        </title>
        <Meta />
        <Links />
        <link
          href="https://fonts.googleapis.com/css2?family=Anton&family=Inter:wght@300;400;500;600&display=swap"
          rel="stylesheet"
        />
      </head>
      <body className="flex h-screen items-center justify-center">
        <div className="flex flex-col items-center text-center">
          <img
            src={errorImage}
            alt="Error illustration"
            className="w-full max-w-[204px] object-cover"
          />
          <h1 className="mt-8 font-inter text-4xl font-semibold leading-8 text-slate-900">
            Something went wrong!
          </h1>
          <p className="mt-6 font-inter text-lg font-normal text-slate-600">
            We can&apos;t seem to find the page you&apos;re looking for.
            <br />
            The link you followed may be broken, or an error has happened.
          </p>
          <Button
            size="flat"
            className="mt-10 w-40 shrink-0 rounded-lg px-4 font-inter"
          >
            <Link to={landingPage()} prefetch="intent">
              Go to website
            </Link>
          </Button>
        </div>
        <Scripts />
      </body>
    </html>
  );
}
