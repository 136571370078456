import { useEffect } from 'react';
import { useMatches } from 'react-router';

import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  json,
  useLoaderData,
  useLocation,
  useRouteError
} from '@remix-run/react';
import * as Sentry from '@sentry/remix';
import { captureRemixErrorBoundaryError } from '@sentry/remix';

import './styles.css';
import { envServer } from '../../serverEnvSchema';

import { ErrorPage } from './components/errorPage/ErrorPage';
import { ExternalScripts } from './components/ExternalScripts';
import { MetaPixel } from './components/MetaPixel';

export async function loader() {
  return json({
    env: {
      APP_NAME: envServer.APP_NAME,
      AWS_BUCKET_URL:
        envServer.AWS_ENDPOINT_URL_S3 + '/' + envServer.AWS_BUCKET_NAME,
      APP_URL: envServer.APP_URL,
      GTM_TRACKING_ID: envServer.GTM_TRACKING_ID,
      ENV: envServer.NODE_ENV,
      APP_ENV: envServer.APP_ENV,
      RELEASE_VERSION: envServer.RELEASE_VERSION,
      SENTRY_CLIENT_KEY: envServer.SENTRY_CLIENT_KEY
    }
  });
}

export default function App() {
  const { env } = useLoaderData<typeof loader>();

  if (env.ENV === 'production') {
    if (!env.SENTRY_CLIENT_KEY) {
      console.log(
        'Sentry client key not provided! Using default "onezee-pr" project'
      );
    }
    Sentry.init({
      dsn:
        env.SENTRY_CLIENT_KEY ||
        'https://0a6fb870d12b77a2849482bc6d19197e@o4507593125462016.ingest.de.sentry.io/4507621735202896',
      integrations: [
        Sentry.browserTracingIntegration({
          useEffect,
          useLocation,
          useMatches
        })
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for tracing.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/onezee\.io\/api/],
      release: env.RELEASE_VERSION,
      environment: env.APP_ENV
    });
  } else {
    console.log('Non-production environment, skipping sentry init');
  }

  return (
    <html lang="en" className="size-full scroll-smooth bg-slate-100">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        <link
          href="https://fonts.googleapis.com/css2?family=Anton&family=Inter:wght@300;400;500;600&display=swap"
          rel="stylesheet"
        />
        {env.ENV === 'production' && <MetaPixel />}
      </head>
      <body className="min-h-full bg-slate-50 sm:size-full">
        <Outlet />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.env = ${JSON.stringify(env)}`
          }}
        />
        <ScrollRestoration />
        <Scripts />
        <ExternalScripts
          gtmTrackingId={env.GTM_TRACKING_ID}
          environment={env.ENV}
        />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();

  captureRemixErrorBoundaryError(error);

  return <ErrorPage error={error} />;
};
